.scroll_list-group{
    max-height: 350px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; 

    box-sizing: content-box; 
}

.scroll_list-int-table{
    max-height: 520px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; 

    box-sizing: content-box; 
}


.modalScroll{
    max-height: 400px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; 

    box-sizing: content-box; 
}

.ticketScroll{
    max-height: 1250px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; 

    box-sizing: content-box; 
}

.header{
    position: absolute;
}



::-webkit-scrollbar {
    width: 4px;
    background:lightgray;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgb(0, 51, 153); 
    background: #6699ff;
} 


